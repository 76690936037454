import classnames from 'classnames';
import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import truncate from 'app/utilities/truncate';
import React, { memo, useCallback, useState  } from 'react';

const DESCRIPTION_CHARACTER_LIMIT = 80;

const Tile = memo(function Tile({ item, index }) {
    const {
        name,
        short_description: description,
        thumbnail,
        subtitle
    } = item.data;

    const style = thumbnail ? { backgroundImage: `url(${thumbnail})` } : {};

    const componentClass = classnames('tile', {
        'has-subtitle': subtitle
    });

    const renderLink = useCallback((item, className, children) => {
        if (item.type === 'external') {
            return (
                <a href={item.url} target="_blank" rel="noreferrer" className={className}>
                    {children}
                </a>
            );
        }

        return (
            <EntityLink
                item={item}
                promoterBuName="RFA Corporate"
                className={className}>
                {children}
            </EntityLink>
        );
    }, []);

    return (
        <article className={componentClass} key={index}>
            <div className="tile-inner">
                {subtitle && <p className="tile-subtitle || heading-6">{subtitle}</p>}
                {renderLink(item, null, (
                    <>
                        <div className="tile-thumbnail" style={style}></div>
                        <h4 className="tile-title">{name}</h4>
                    </>
                ))}
                <p className="tile-description">
                    {truncate(description, DESCRIPTION_CHARACTER_LIMIT)}
                </p>
                {renderLink(item, 'text-link', (
                    <>
                        {item.type === 'external' ? 'Learn more' : 'Read More'}
                        <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                    </>
                ))}
            </div>
        </article>
    );
});

Tile.propTypes = {
    item: PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string,
        data: PropTypes.shape({
            name: PropTypes.string.isRequired,
            // eslint-disable-next-line camelcase
            short_description: PropTypes.string,
            thumbnail: PropTypes.string,
            subtitle: PropTypes.string
        }).isRequired
    }).isRequired,
    index: PropTypes.number.isRequired
};


const AboutUsTile = memo(function AboutUsTile({ item, index, onToggleModal }) {
    const { name, role, file } = item;
    const style = file ? { backgroundImage: `url(${file})` } : {};

    const componentClass = classnames('tile', {
        'has-subtitle': role
    });

    const handleLearnMore = () => {
        // Add profile query parameter to URL
        const profileParam = name.toLowerCase().replace(/\s+/g, '-');
        const url = new URL(window.location);
        url.searchParams.set('profile', profileParam);
        window.history.pushState({}, '', url);

        onToggleModal(item);
    };

    return (
        <article className={componentClass} key={index}>
            <div className="tile-inner">
                <div>
                    <button className="tile-thumbnail" style={style} onClick={handleLearnMore}></button>
                    <h4 className="tile-title">{name}</h4>
                </div>
                <p className="tile-description">
                    {truncate(role, DESCRIPTION_CHARACTER_LIMIT)}
                </p>
                <button className="text-link" onClick={handleLearnMore}>
                    Learn more
                    <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                </button>
            </div>
        </article>
    );
});

AboutUsTile.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        role: PropTypes.string,
        file: PropTypes.string
    }).isRequired,
    index: PropTypes.number.isRequired,
    onToggleModal: PropTypes.func.isRequired
};


const BioContent = memo(function BioContent({ selectedItem }) {
    const { name, role, linkedin, file, content } = selectedItem || {};

    return (
        <div className="about-us">
            <div className="thumbnail">
                {file && <img className="thumbnail-image" src={file} alt={name} />}
                <h2>{name}</h2>
                {role && <p className="role">{role}</p>}
            </div>
            <div className="content">
                {linkedin && (
                    <a href={linkedin} target="_blank" rel="noreferrer">
                        <Icon name="linkedin" width={60} height={60} />
                    </a>
                )}
                <div
                    className="content-inner"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    );
});

BioContent.propTypes = {
    selectedItem: PropTypes.shape({
        name: PropTypes.string.isRequired,
        role: PropTypes.string,
        linkedin: PropTypes.string,
        file: PropTypes.string,
        content: PropTypes.string
    })
};


function ContentSet({ items, title, rows, type }) { // eslint-disable-line require-jsdoc
    const [selectedItem, setSelectedItem] = useState(() => {
        // Check URL for profile query parameter on initial render
        if (type === 'aboutus' && typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            const profileName = urlParams.get('profile');

            if (profileName) {
                const normalizedProfileName = profileName.replace(/-/g, ' ');
                const matchingItem = items.find((item) =>
                    item.name.toLowerCase() === normalizedProfileName.toLowerCase()
                );

                return matchingItem || null;
            }
        }

        return null;
    });
    const [displayRows, setDisplayRows] = useState(parseInt(rows) || 1);

    const toggleModalHandler = useCallback((item) => {
        if (type === 'aboutus') {
            if (item) {
                // URL handling is now in AboutUsTile component
            } else {
                // Remove profile parameter when closing modal
                const url = new URL(window.location);
                url.searchParams.delete('profile');
                window.history.pushState({}, '', url);
            }
        }
        setSelectedItem(item || null);
    }, [type]);

    const viewMoreBtnClickHandler = useCallback(() =>
        setDisplayRows((oldRows) => oldRows + 2), []);

    const viewLessBtnClickHandler = useCallback(() =>
        setDisplayRows(parseInt(rows) || 1), [rows]);

    const renderGrid = useCallback(() => {
        const itemsPerLine = 4;
        // Show all items if rows is 0 and type is aboutus
        const shouldDisplayViewMoreToggle = type === 'aboutus' && rows === '0'
            ? false
            : rows * itemsPerLine < items.length;

        // Display all items if rows is 0 and type is aboutus, otherwise use normal logic
        const displayCount = type === 'aboutus' && rows === '0'
            ? items.length
            : Math.min(displayRows * itemsPerLine, items.length);

        const displayItems = items.slice(0, displayCount);
        const showViewMore = displayCount < items.length;

        return (
            <>
                <div className="tile-block-inner">
                    {displayItems.map((item, index) =>
                        (type === 'aboutus' ? (
                            <AboutUsTile
                                key={index}
                                item={item}
                                index={index}
                                onToggleModal={toggleModalHandler}
                            />
                        ) : (
                            <Tile
                                key={index}
                                item={item}
                                index={index}
                            />
                        ))
                    )}
                </div>
                {shouldDisplayViewMoreToggle && (
                    <div className="tile-block-view-more">
                        {showViewMore ? (
                            <button
                                className="button alternative || view-more"
                                onClick={viewMoreBtnClickHandler}
                            >
                                View more
                            </button>
                        ) : (
                            <button
                                className="button alternative || view-more"
                                onClick={viewLessBtnClickHandler}
                            >
                                View less
                            </button>
                        )}
                    </div>
                )}
                <Modal
                    isOpen={Boolean(selectedItem)}
                    toggleModalHandler={toggleModalHandler}
                >
                    <BioContent selectedItem={selectedItem} />
                </Modal>
            </>
        );
    }, [items, type, displayRows, selectedItem, rows]);

    return (
        <section className="content-set">
            <div className="tile-block || constrain-width large">
                {title && <h2 className="tile-block-title">{title}</h2>}
                {renderGrid()}
            </div>
        </section>
    );
}


ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    rows: PropTypes.string,
    type: PropTypes.string
};

export default ContentSet;
